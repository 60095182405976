import axios from 'axios'

// initial state
const state = {
  endpoint: "/api/v1/network_animators",
  all: [],
  initialState: []
}

// getters
const getters = {
}

// actions
const actions = {
  getAllNetworkAnimators ({ commit }, params) {
    axios.get(state.endpoint, { params }).then(({ data }) => commit('setNetworkAnimators', data.data))
  },

  resetStateAll (context) {
    context.commit('resetStateAll')
  }
}

// mutations
const mutations = {
  setNetworkAnimators (state, networkAnimators) {
    state.all = networkAnimators
  },

  resetStateAll (state) {
    state.all = []
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
