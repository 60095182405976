import Vue from 'vue'
import Vuex from 'vuex'
import appointments from './modules/appointments'
import availabilities from './modules/availabilities'
import weeklyAvailabilities from './modules/weekly-availabilities'
import patients from './modules/patients'
import offices from './modules/offices'
import zones from './modules/zones'
import users from './modules/users'
import practitioners from './modules/practitioners'
import practitionerAddresses from './modules/practitioner-addresses'
import referents from './modules/referents'
import enrollments from './modules/enrollments'
import trainers from './modules/trainers'
import trainees from './modules/trainees'
import guestSpeakers from './modules/guest-speakers'
import networkAnimators from './modules/network-animators'
import trainings from './modules/trainings'
import trainingSessions from './modules/training-sessions'
import dispatchers from './modules/dispatchers'
import populations from './modules/populations'
import pathologies from './modules/pathologies'
import companies from './modules/companies'
import sites from './modules/sites'
import sessions from './modules/sessions'

const debug = process.env.NODE_ENV !== 'production'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    appointments,
    availabilities,
    weeklyAvailabilities,
    patients,
    offices,
    users,
    practitioners,
    practitionerAddresses,
    referents,
    enrollments,
    trainers,
    trainees,
    guestSpeakers,
    networkAnimators,
    trainings,
    trainingSessions,
    dispatchers,
    zones,
    populations,
    pathologies,
    companies,
    sites,
    sessions
  },
  strict: debug
})
